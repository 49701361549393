<template>
    <div style="overflow: 'hidden scroll'">
        <!-- Loading -->
        <div class="loading" v-if="loading">
            <!-- <v-progress-circular
                :rotate="360"
                :size="100"
                :width="10"
                :value="loadingVal"
                indeterminate
                color="white"
            >
                {{ loadingVal }}
            </v-progress-circular> -->
            <v-img
                width="35"
                height="35"
                src="../assets/loading.png"
                style="position: absolute; margin-top: -80px"
                class="loading-animate"
            ></v-img>
            <span class="white--text">{{ loadingVal }}%</span>
        </div>
        <!-- Loading end -->

        <div
            :style="{
                backgroundImage: 'url(' + backgroundUrl + ')',
                backgroundSize: '100%',
                height: source == 'sub' ? contentHeight : bgHeight,
            }"
            :class="source == 'sub' ? 'sub-bg' : ''"
        >
            <v-app-bar
                flat
                color="transparent"
                dark
                fixed
                class="pt-1"
                style="background-color: #0a1332; z-index: 2"
            >
                <v-toolbar-title>
                    <v-img max-width="130" src="../assets/logo.png"></v-img>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                ></v-app-bar-nav-icon>
            </v-app-bar>

            <div
                class="content"
                :style="{
                    height: source == undefined ? 'auto' : contentHeight,
                    padding: source == 'sub' ? '0 16px' : '0',
                    marginTop: source == 'img' ? '-60px' : '',
                    paddingBottom: source == 'img' ? '70px' : '150px',
                    overflowY: source == undefined ? 'hidden' : 'auto',
                }"
            >
                <slot></slot>
            </div>

            <v-navigation-drawer
                app
                v-model="drawer"
                fixed
                right
                dark
                color="rgb(12 18 58 / 76%)"
                style="right: 0; backdrop-filter: blur(5px); z-index: 2"
            >
                <v-list-item>
                    <v-spacer></v-spacer>

                    <v-btn text fab dark small @click="drawer = false">
                        <v-icon dark> mdi-close </v-icon>
                    </v-btn>
                </v-list-item>

                <v-divider></v-divider>

                <v-list dense>
                    <v-list-item
                        v-for="item in menuLinks"
                        :key="item.title"
                        link
                        @click="$router.push(item.url)"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title
                                class="light-blue--text text--lighten-4"
                                >{{ item.title }}</v-list-item-title
                            >
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
        </div>

        <v-bottom-navigation
            v-model="bottom"
            height="70"
            fixed
            dark
            scroll-target
            background-color="rgba(47, 65, 126, 0.97)"
            active-class=""
            grow
        >
            <!-- style="padding-left: 60%" -->
            <v-btn
                :value="idx"
                v-for="(bottom, idx) of bottomList"
                :key="idx"
                v-ripple="false"
                @click="
                    bottom.url.includes('http')
                        ? open(bottom.url)
                        : $router.push(bottom.url)
                "
                :class="tab == idx ? 'v-btn--active' : 'no-active'"
            >
                <span>{{ bottom.name }}</span>

                <!-- <v-icon>{{ bottom.icon }}</v-icon> -->
                <svg class="icon" aria-hidden="true">
                    <use :xlink:href="'#icon-' + bottom.icon"></use>
                </svg>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>

<script>
export default {
    name: 'MobileLayout',

    props: {
        source: String,
        tab: String,
    },

    data: () => ({
        loadingVal: 0,
        bgHeight: '100vh',
        loading: true,
        bottom: '',
        drawer: false,
        contentHeight: '100%',
        menuLinks: [
            {
                title: '首页',
                icon: 'mdi-circle-small',
                url: '/m',
            },
        ],
        bottomList: [
            {
                icon: 'shouye-01',
                name: '首页',
                url: '/m',
            },
            {
                icon: 'yicheng',
                name: '议程',
                url: '/m/agenda',
            },
            {
                icon: 'baoming',
                name: '报名',
                url: 'http://lxh5.31huiyi.com/guoyaoqixie2021/',
            },
            {
                icon: 'zhinan',
                name: '指南',
                url: '/m/guide',
            },
            // {
            //     icon: 'xinwen',
            //     name: '新闻',
            //     url: '/m/news',
            // },
            // {
            //     icon: 'jiabin-01',
            //     name: '嘉宾',
            //     url: '/m/guest',
            // },
            // {
            //     icon: 'hezuo',
            //     name: '合作',
            //     url: '/m/partner',
            // },
            {
                icon: 'guanyu-01',
                name: '关于',
                url: '/m/about',
            },
        ],
    }),

    computed: {
        backgroundUrl() {
            return this.source == 'sub'
                ? require('../assets/m_bg2.png')
                : require('../assets/bg.jpeg')
        },
    },

    beforeMount() {
        this.$share()
    },

    mounted() {
        let timer = setInterval(() => {
            this.loadingVal += 10
            if (this.loadingVal == 110) {
                clearInterval(timer)
                this.loading = false
                this.bindShared()
            }
        }, 100)
    },

    created() {},

    methods: {
        open(url) {
            window.open(url, '_blank')
        },
        bindShared() {
            // this.$nextTick(() => {
            //     fetch(
            //         'https://cmdcapi.31huiyi.com/wechat/shared?url=' +
            //             location.href.split('#')[0]
            //     )
            //         .then((response) => response.json())
            //         .then((res) => {
            //             let wxConfig = JSON.parse(res.wechat)
            //             wxConfig.debug = true
            //             wx.config(wxConfig)
            //             wx.ready(() => {
            //                 wx.error((err) => {
            //                     window.alert(err)
            //                 })
            //                 let data = {
            //                     name: '欢迎',
            //                     desc: '描述信息...',
            //                     image_url: res.image_url,
            //                 }
            //                 let url = 'https://cmdc.31huiyi.com'
            //                 console.log(1111122222)
            //                 wx.updateAppMessageShareData({
            //                     title: data.name,
            //                     desc: data.desc,
            //                     link: url,
            //                     imgUrl: data.image_url,
            //                     success: () => {},
            //                     fail: () => {},
            //                 })
            //                 wx.updateTimelineShareData({
            //                     title: data.name,
            //                     link: url,
            //                     imgUrl: data.image_url,
            //                     success: () => {},
            //                     fail: () => {},
            //                 })
            //             })
            //         })
            //     // let contentHeight = document.querySelector('.content').offsetHeight
            //     // if (contentHeight && this.source == 'sub') {
            //     //     // this.contentHeight = contentHeight + 300 + 'px'
            //     //     console.log(this.contentHeight)
            //     // }
            // })
        },
    },
}
</script>

<style lang="scss" scoped>
h1 {
    font-size: 1.8em;
}

.v-item-group.v-bottom-navigation {
    backdrop-filter: blur(5px);
    overflow: scroll;
    // padding-left: 240px;
}
.loading {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    position: fixed;
    background: rgb(13 15 20 / 99%);
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;
    // backdrop-filter: blur(8px);
}

.content {
    position: absolute;
    top: 60px;
    color: #fff;
    padding: 0 16px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.content:before {
    content: '';
    height: 70px;
}

// .content > .v-image {
//     margin-top: -60px;
// }

.icon {
    width: 2em;
    height: 2em;
    margin-bottom: 5px;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

.v-btn--active.no-active {
    opacity: 0.7 !important;
}

.theme--dark.v-btn:hover::before {
    opacity: 0;
}

.sub-bg {
    height: 100vh;
    position: fixed;
    z-index: 2;
    width: 100%;
}

@keyframes rotate-y {
    0% {
        transform: rotateY(0deg);
        transition: 0.2s;
        transform-style: preserve-3d;
    }
    50% {
        transform: rotateY(45deg);
        transition: 0.2s;
        transform-style: preserve-3d;
    }
    100% {
        transform: rotateY(360deg);
        transition: 0.2s;
        transform-style: preserve-3d;
    }
}

.loading-animate {
    animation: rotate-y 1.2s linear infinite;
}
</style>
