var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"'hidden scroll'"}},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('v-img',{staticClass:"loading-animate",staticStyle:{"position":"absolute","margin-top":"-80px"},attrs:{"width":"35","height":"35","src":require("../assets/loading.png")}}),_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.loadingVal)+"%")])],1):_vm._e(),_c('div',{class:_vm.source == 'sub' ? 'sub-bg' : '',style:({
            backgroundImage: 'url(' + _vm.backgroundUrl + ')',
            backgroundSize: '100%',
            height: _vm.source == 'sub' ? _vm.contentHeight : _vm.bgHeight,
        })},[_c('v-app-bar',{staticClass:"pt-1",staticStyle:{"background-color":"#0a1332","z-index":"2"},attrs:{"flat":"","color":"transparent","dark":"","fixed":""}},[_c('v-toolbar-title',[_c('v-img',{attrs:{"max-width":"130","src":require("../assets/logo.png")}})],1),_c('v-spacer'),_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}})],1),_c('div',{staticClass:"content",style:({
                height: _vm.source == undefined ? 'auto' : _vm.contentHeight,
                padding: _vm.source == 'sub' ? '0 16px' : '0',
                marginTop: _vm.source == 'img' ? '-60px' : '',
                paddingBottom: _vm.source == 'img' ? '70px' : '150px',
                overflowY: _vm.source == undefined ? 'hidden' : 'auto',
            })},[_vm._t("default")],2),_c('v-navigation-drawer',{staticStyle:{"right":"0","backdrop-filter":"blur(5px)","z-index":"2"},attrs:{"app":"","fixed":"","right":"","dark":"","color":"rgb(12 18 58 / 76%)"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","fab":"","dark":"","small":""},on:{"click":function($event){_vm.drawer = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menuLinks),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":""},on:{"click":function($event){return _vm.$router.push(item.url)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"light-blue--text text--lighten-4"},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1),_c('v-bottom-navigation',{attrs:{"height":"70","fixed":"","dark":"","scroll-target":"","background-color":"rgba(47, 65, 126, 0.97)","active-class":"","grow":""},model:{value:(_vm.bottom),callback:function ($$v) {_vm.bottom=$$v},expression:"bottom"}},_vm._l((_vm.bottomList),function(bottom,idx){return _c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:(false),expression:"false"}],key:idx,class:_vm.tab == idx ? 'v-btn--active' : 'no-active',attrs:{"value":idx},on:{"click":function($event){bottom.url.includes('http')
                    ? _vm.open(bottom.url)
                    : _vm.$router.push(bottom.url)}}},[_c('span',[_vm._v(_vm._s(bottom.name))]),_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":'#icon-' + bottom.icon}})])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }